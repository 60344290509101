 <template >
  <div class="graybox">
      <div class="shop-logo"><img :src="shop.pic" /></div>
    <div class="mybox">
      <h4>{{ shop.title }}</h4>
      <div class="normal-box">
        {{shop.content}}
      </div>
    </div>
        <!-- 底部菜单 -->
    <nut-tabbar :bottom="true" @tab-switch="tabSwitch">
      <nut-tabbar-item tab-title="店铺首页"  icon="dshop" to="/shop"></nut-tabbar-item>
      <nut-tabbar-item
        tab-title="公司简介"
        icon="order"
        
      ></nut-tabbar-item>
      <nut-tabbar-item
        tab-title="联系我们"
        icon="service"
        @click="getChat"
      ></nut-tabbar-item>
    </nut-tabbar>
  </div>
</template>
      <script>
import { defineComponent, reactive } from 'vue'

export default defineComponent({
    name:'about',
  setup() {
    const back = (event) => {
      console.log(event)
    }
    const shop =reactive({
        pic:'https://img.zcool.cn/community/01afad6179190011013e8943a484c3.jpg@260w_195h_1c_1e_1o_100sh.jpg',
        title:'山东山大华天软件有限公司',
        content:'Icon 的 name 属性支持传入图标名称或图片链接。Icon 的 name 属性支持传入图标名称或图片链接。Icon 的 name 属性支持传入图标名称或图片链接。Icon 的 name 属性支持传入图标名称或图片链接。Icon 的 name 属性支持传入图标名称或图片链接。'
    })
    return{
        shop,
        back
    }
  },
})
</script>
